import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/SettingsBackground.png";
import { Button } from "@mui/material";
import TokenModal from "../TokenModal";
import { connect } from "react-redux";
import Public from "../Public";
import { useMaterialUIController } from "context";
import { Tabs } from 'antd';

function Header({ children, user }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  var avatarLetter = '';
  avatarLetter = localStorage.getItem("mail");
  avatarLetter = avatarLetter.slice(0, 2).toUpperCase();
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(true);
  }

  const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const items = [
    {
      key: '1',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Private</h1>,
      children: 
        <>
          <TokenModal open={open} setOpen={setOpen} />
          <div className="md:flex justify-between">
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <h1  className="text-4xl font-bold rounded-full bg-blue-500 text-white p-4">{avatarLetter}</h1>
              </Grid>
              <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {localStorage.getItem('mail')}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center" style={{ marginLeft: 1, marginTop: 4, marginRight: 24 }}>
              <div className="w-full flex md:justify-end mb-5 md:mb-0">
                <div className="flex">
                  <div className="content-center mr-5">
                    <MDBox mt={0.5} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Trader Token :
                      </MDTypography>
                    </MDBox>
                  </div>
                  <div>
                    <MDBox mt={0.5} lineHeight={1}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleModal}
                        size='medium'
                        disabled={!user?.isSubscribed}
                        style={{ marginRight: "10px", color: "white", fontSize: "14px" }}
                        id="getToken"
                      >
                        Get Token
                      </Button>
                    </MDBox>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
          {children}
        </>
    },
    {
      key: '2',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Public</h1>,
      children: <Public />,
    }
  ];

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.2),
              rgba(gradients.info.state, 0.2)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, null)(Header);