import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {InputLabel, Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MDSnackbar from "components/MDSnackbar";

const Symbol = ({ saveSymbol, symbolData, darkMode, textColor, symbols }) => {
    
    const [symbol, setSymbol] = useState({ id: "", addProp: "", addValue: "" });

    useEffect(()=> {
        setSymbol({
            ...symbol,
            id: symbolData?.id,
            addProp: symbolData?.addProp,
            addValue: symbolData?.addValue
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbolData]);

    const removeSymbol = (addProp) => {
        let temp = symbols.filter(symbol => symbol.addProp !== addProp);
        saveSymbol(temp, 1);
    }
    
    return (
        <Grid container spacing={1} className='p-4 justify-center sm:p-[1px]'>
            <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
                <FormControl variant='standard' className='w-full'>
                    <InputLabel>Prop</InputLabel>
                    <Input
                        id='addProp'
                        className='h-10'
                        type='text'
                        variant="standard"
                        label="Prop"
                        value={symbol?.addProp}
                        readOnly
                        startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
                <div className="m-auto">
                    <ArrowForwardIcon 
                        sx={{
                        '@media (min-width:200px)': {
                            width: '19px',
                            height: '13px',
                            color: textColor,
                            '&:hover': {
                            width: '22px',
                            height: '15px',
                            },
                        },
                        '@media (min-width:500px)': {
                            width: '28px',
                            height: '18px',
                            '&:hover': {
                            width: '30px',
                            height: '20px',
                            },
                        },
                        '@media (min-width:800px)': {
                            width: '33px',
                            height: '21px',
                            '&:hover': {
                            width: '36px',
                            height: '24px',
                            },
                        },
                    }}/>
                </div>
            </Grid>
            <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
                <FormControl variant='standard' className='w-full'>
                    <InputLabel>Value</InputLabel>
                    <Input
                        id='addValue'
                        className='h-10'
                        type='text'
                        variant="standard"
                        label="Value"
                        value={symbol?.addValue}
                        readOnly
                        startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
                <div className="m-auto">
                    <HorizontalRuleIcon onClick={()=> {removeSymbol(symbol.addProp)}} 
                        sx={{
                        '@media (min-width:200px)': {
                            width: '19px',
                            height: '19px',
                            color: textColor,
                            '&:hover': {
                            width: '22px',
                            height: '22px',
                            },
                        },
                        '@media (min-width:500px)': {
                            width: '28px',
                            height: '28px',
                            '&:hover': {
                            width: '30px',
                            height: '30px',
                            },
                        },
                        '@media (min-width:800px)': {
                            width: '33px',
                            height: '33px',
                            '&:hover': {
                            width: '36px',
                            height: '36px',
                            },
                        },
                    }}/> 
                </div>
            </Grid>
        </Grid>
        );
}

function SymbolMapper ({ darkMode, textColor, symbols, saveSymbol }) {

    const [symbol, setSymbol] = useState({ id: "", addProp: "", addValue: "" });

    const [errorSB, setErrorSB] = useState(false);
    const [content, setContent] = useState("");
    const closeErrorSB = () => setErrorSB(false);

    
    const renderErrorSB = (
        <MDSnackbar
        color='error'
        icon='warning'
        title='Symbol Mapper'
        content={content}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        />
    );

    const handleChange = (e) => {
        setSymbol({
            ...symbol,
            [e.target.id]: e.target.value
        });
    }

    const addSymbol = () => {
        let temp = [...symbols];
        if (symbol.addProp === "" || symbol.addValue === "") {
            setContent("The Prop and Value are required !");
            setErrorSB(true);
            return;
        }
        if (temp.some(item => item.addProp === symbol.addProp)) {
            setContent("Prop already exists !");
            setErrorSB(true);
            return;
        }
        setSymbol({
            ...symbol,
            id: temp.length
        });
        let t_symbol = symbol;
        t_symbol.id = temp.length;
        temp.push(t_symbol);
        saveSymbol(temp, 1);
        setSymbol({
            ...symbol,
            addProp: "",
            addValue: ""
        })
    }

    return (
        <div>
            <Grid container spacing={1} className='p-4 justify-center sm:p-[1px]'>
                {renderErrorSB}
                <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
                    <FormControl variant='standard' className='w-full'>
                        <InputLabel>Prop</InputLabel>
                        <Input
                            id='addProp'
                            className='h-10'
                            type='text'
                            variant="standard"
                            label="Prop"
                            value={symbol?.addProp}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position='start'>
                                    {darkMode?
                                    <SettingsIcon style={{ color: '#ffffff' }}/>
                                    :
                                    <SettingsIcon />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
                    <div className="m-auto">
                        <ArrowForwardIcon 
                            sx={{
                            '@media (min-width:200px)': {
                                width: '19px',
                                height: '13px',
                                color: textColor,
                                '&:hover': {
                                width: '22px',
                                height: '15px',
                                },
                            },
                            '@media (min-width:500px)': {
                                width: '28px',
                                height: '18px',
                                '&:hover': {
                                width: '30px',
                                height: '20px',
                                },
                            },
                            '@media (min-width:800px)': {
                                width: '33px',
                                height: '21px',
                                '&:hover': {
                                width: '36px',
                                height: '24px',
                                },
                            },
                        }}/>
                    </div>
                </Grid>
                <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
                    <FormControl variant='standard' className='w-full'>
                        <InputLabel>Value</InputLabel>
                        <Input
                            id='addValue'
                            className='h-10'
                            type='text'
                            variant="standard"
                            label="Value"
                            value={symbol?.addValue}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position='start'>
                                    {darkMode?
                                    <SettingsIcon style={{ color: '#ffffff' }}/>
                                    :
                                    <SettingsIcon />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
                    <AddIcon onClick={addSymbol}
                        sx={{
                        '@media (min-width:200px)': {
                            width: '19px',
                            height: '19px',
                            color: textColor,
                            '&:hover': {
                            width: '22px',
                            height: '22px',
                            },
                        },
                        '@media (min-width:500px)': {
                            width: '28px',
                            height: '28px',
                            '&:hover': {
                            width: '30px',
                            height: '30px',
                            },
                        },
                        '@media (min-width:800px)': {
                            width: '33px',
                            height: '33px',
                            '&:hover': {
                            width: '36px',
                            height: '36px',
                            },
                        },
                    }}/>
                </Grid>
            </Grid>
            {   
                symbols?.map((symbol, index) => (
                    <Symbol symbolData={symbol} index={index} darkMode={darkMode} textColor={textColor} saveSymbol={saveSymbol} symbols={symbols} />
                ))
            }
        </div>
    )
}

export default SymbolMapper;