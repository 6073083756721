import Admin from './Pages/admin';
import Dashboard from './Pages/dashboard';
import TradeCopy from './Pages/trade';
import Account from './Pages/profile';
import InfoPage from './Pages/infoPage';
import DownloadTutorial from './Pages/downloadTutorial';
import Billing from './Pages/billing';
import SignIn from "Pages/authentication/sign-in";
import Icon from "@mui/material/Icon";
import GettingStarted from "./Pages/gettingStarted";

const routes = [
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/admin",
    component: <Admin />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Getting-started",
    key: "getting_started",
    icon: <Icon fontSize="small">directions_run</Icon>,
    route: "/getting-started",
    component: GettingStarted,
  },
  {
    type: "collapse",
    name: "Trade Link",
    key: "trade-link",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/trade-link",
    component: TradeCopy,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/accounts",
    component: <Account />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Tutorials/Downloads",
    key: "tutorials_downloads",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/tutorials-downloads",
    component: <DownloadTutorial />,
  },
  {
    type: "collapse",
    name: "Info",
    key: "Info",
    icon: <Icon fontSize="small">question_mark</Icon>,
    route: "/info",
    component: <InfoPage />,
  },
  {
    type: "collapse",
    name: "Cancel Subscription",
    key: "cancel_subscription",
    icon: <Icon fontSize="small">cancel</Icon>,
    action: () => window.open("https://billing.stripe.com/p/login/3csdSgcZM4lWgJW288", "_blank"),
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/logout",
    component: <SignIn />,
  },
];

export default routes;
