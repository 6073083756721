import { connect } from "react-redux";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "Pages/profile/components/Header";
import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import { getCurrentProfile } from "../../api/profile";
import { loadUser } from "api/auth";
import ListPlatformsComponent from "./components/ListPlatformsComponent";

function Profile({ getCurrentProfile, loadUser, user }) {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    getCurrentProfile();
    if (user === null) {
      loadUser();
    }
  }, [getCurrentProfile, loadUser, user]);

  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='Profile'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='Profile'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color='warning'
      icon='star'
      title='Profile'
      content={content}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='Profile'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  return (
    <DashboardLayout>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      {renderInfoSB}
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <ListPlatformsComponent
          setSuccessSB={setSuccessSB}
          setErrorSB={setErrorSB}
          setContent={setContent}
        />
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile, loadUser })(Profile);