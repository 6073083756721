import * as React from 'react';
import Button from '@mui/material/Button';
import { useMaterialUIController } from "context";
import { API_ENDPOINTS } from 'apiConfig';
import axiosHelper from "../../../Utilities/axiosHelper";
import MDSnackbar from 'components/MDSnackbar';
import { loadUser } from 'api/auth';
import { connect } from 'react-redux';

function ControlPanel ({ loadUser }) {

    const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
    const [successSB, setSuccessSB] =  React.useState(false);
    const [errorSB, setErrorSB] =  React.useState(false);
    const [content, setContent] =  React.useState("");
  
    const closeSuccessSB = () => setSuccessSB(false);
    const closeErrorSB = () => setErrorSB(false);

    const renderSuccessSB = (
        <MDSnackbar
          color='success'
          icon='check'
          title='Admin'
          content={content}
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );
    
      const renderErrorSB = (
        <MDSnackbar
          color='error'
          icon='warning'
          title='Admin'
          content={content}
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          bgWhite
        />
      );

    const setNewContent = async() => {
        let res = await axiosHelper.put(API_ENDPOINTS.admin.setNewContent);
        if (res.status === 200 || res.status === 204) {
            setContent("Successful!");
            setSuccessSB(true);
            loadUser();
        }else{
            setContent(res.message);
            setErrorSB(true);
        }
    }

    const updateDownloadVersions = async() => {
        let res = await axiosHelper.get(API_ENDPOINTS.admin.updateDownloadVersions);
        if (res.status === 200 || res.status === 204) {
            setContent("Successful!");
            setSuccessSB(true);
            loadUser();
        }else{
            setContent(res.message);
            setErrorSB(true);
        }
    }

    return (
        <div className='p-5 space-y-5'>
            {renderSuccessSB}
            {renderErrorSB}
            <Button variant="outlined" onClick={setNewContent} className='mb-5 w-[350px]'><p className={(darkMode? 'text-white text-[17px]' : 'text-black text-[17px]')}>Set users have new content</p></Button>
            <br></br>
            <Button variant="outlined" onClick={updateDownloadVersions} className='text-white w-[350px]'><p className={darkMode? 'text-white text-[17px]' : 'text-black text-[17px]'}>Update Meta Trader Versions</p></Button>
        </div>
    )
}

export default connect(null, {loadUser})(ControlPanel);