import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";

function Header({ children }) {

  return (
    <MDBox mb={5}>
      <Card
        className="px-5"
      >
        {children}
      </Card> 
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
