import React from "react";
import logo from "../../assets/images/logo-ct.png"

function Error() {
    return(
        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/error_bg.jpg'})` }} className="min-h-screen flex flex-col w-full bg-cover bg-center">
            <div className="h-16 flex px-4 bg-gradient-to-r from-transparent from-10% via-blue-200 via-50% to-gray-200 to-80%">
                <img src={logo} className="w-[160px]" alt="" />
            </div>
            <div className="flex flex-1 place-content-center items-center mb-[200px]">
                <h1 className="text-7xl">Oops, an error has occurred</h1>
            </div>
        </div>
    );
}

export default Error;