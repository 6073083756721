import { useEffect } from "react";

import { useLocation, NavLink } from "react-router-dom";
import {connect}  from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import { setChange, setOpenDialog } from "api/trade";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import axiosHelper from '../../Utilities/axiosHelper.js';
import { API_ENDPOINTS } from "../../apiConfig";
import { resetState } from 'api/auth';

function Sidenav({ color, brand, brandName, routes, isChange, setChange, user, setOpenDialog, resetState, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);

  }, [dispatch, transparentSidenav, whiteSidenav]);

  const onClick = (value) => {
    if (value === '/logout') {
      axiosHelper.get(API_ENDPOINTS.user.logout);
      resetState();
      localStorage.clear();
      return;
    }
    localStorage.setItem('url', value);
    if (isChange.length !== 0) {
      setOpenDialog(true);
    }
  }

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, action }) => {
    let returnValue;
  
    if (!user?.roles.includes(0) && key === 'admin') {
      return null;
    } else {
      // Check for Cancel Subscription condition
      if (key === "cancel_subscription" && !user?.isSubscribed) {
        return null;
      }
      if (type === "collapse") {
        if (action) {
          returnValue = (
            <div onClick={action} key={key}>
              <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
            </div>
          );
        } else if (href || isChange.length !== 0) {
          returnValue = (
            <div onClick={() => onClick(route)} key={key}>
              <Link href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
              </Link>
            </div>
          );
        } else {
          returnValue = (
            <div onClick={() => onClick(route)} id={route} key={key}>
              <NavLink to={route}>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
              </NavLink>
            </div>
          );
        }
      } else if (type === "title") {
          returnValue = (
            <div key={key}>
              <MDTypography
                key={key}
                color={textColor}
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                {title}
              </MDTypography>
            </div>
          );
        } else if (type === "divider") {
          returnValue = (
            <div key={key}>
              <Divider
                key={key}
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
            </div>
          );
        }
      }
    return returnValue;

  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <div className="w-full flex">
            {brand && <MDBox component="img" src={brand} alt="Brand" width="8rem" height="50px" className="m-auto" />}
          </div>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List key="MenuList">{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  isChange: state.tradeLink?.isChange,
  user: state.auth?.user
});

export default connect(mapStateToProps, { setChange, setOpenDialog, resetState })(Sidenav);