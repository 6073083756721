import React, { useState } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "Pages/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import brandWhite from "assets/images/logo-ct.png";
import MDSnackbar from "components/MDSnackbar";
import { API_ENDPOINTS } from "../../../../apiConfig";
import axiosHelper from "../../../../Utilities/axiosHelper";
import CustomModal from "components/CustomModal";

function ResetPassword() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeErrorSB = () => setErrorSB(false);
  const [data, setData] = useState({ newPassword: "", rePassword: "" });
  const [newPasswordError, setNewPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const userId = queryParams.get('userId');
  if (token === '' || token === null || userId === '' || userId === null) {
    return <Navigate to="/signin" replace />;
  }

  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='Sign In'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='Sign In'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color='warning'
      icon='star'
      title='Sign In'
      content={content}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='Sign In'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );


  const onChange = (e) => {
    if (e.target.id === "newPassword" && e.target.value !== "") {
      setNewPasswordError("");
    }
    if (e.target.id === "rePassword" && e.target.value !== "") {
      setRePasswordError("");
    }
    setData({
      ...data,
      [e.target.id]: [e.target.value],
    });
  };

  const resetPassword = async() => {
    try {
      var url = API_ENDPOINTS.user.resetPassword + "?token=" + token;
      let response = await axiosHelper.post(url, {userId: userId, newPassword: data['newPassword'][0]});
      if (response.status === 200) {
        localStorage.removeItem('authToken');
        setOpen(true);
      }else{
        setRePasswordError("There was an error resetting your passsword. Please email support at support@copiercat.com");
        setContent("Password reset failed");
        setErrorSB(true);
        return;
      }
    } catch (error) {
      setRePasswordError("There was an error resetting your passsword. Please email support at support@copiercat.com");
    }
  }

  const onResetPassword = (event) => {
    event.preventDefault();
    if (data["newPassword"][0] === "" || data["newPassword"] === "") {
      setNewPasswordError("Please reset new password!");
      setErrorSB(true);
      setContent("Please reset new password!");
      return;
    }
    if (data["rePassword"][0] === "" || data["rePassword"] === "") {
      setRePasswordError("Please confirm new password!");
      setErrorSB(true);
      setContent("Password is required!");
      return;
    }
    if (data["rePassword"][0] !== data["newPassword"][0]) {
      setRePasswordError("Passwords do not match");
      setErrorSB(true);
      setContent("Passwords do not match");
      return;
    }
    resetPassword(); 
  };

  const onSignin = () => {
    localStorage.removeItem("authToken");
  }


  return (
    <BasicLayout image={bgImage}>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      {renderInfoSB}
      <CustomModal open={open} setOpen={setOpen} resetState={null} title="Reset" content="Your password has been reset please log in with your new password" />
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <div className='w-full flex'>
            <img
              src={brandWhite}
              style={{ width: 240, height: 110 }}
              alt=''
              className='mx-auto rounded-full'
            />
          </div>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                type='password'
                id='newPassword'
                label='New Password'
                fullWidth
                value={data["newPassword"]}
                onChange={(e) => onChange(e)}
                required
              />
              {newPasswordError && (
                <div className='error' style={{ fontSize: 15, color: "red" }}>
                  {newPasswordError}
                </div>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type='password'
                label='Confirm Password'
                fullWidth
                id='rePassword'
                value={data["rePassword"]}
                onChange={(e) => onChange(e)}
                required
              />
              {rePasswordError && (
                <div className='error' style={{ fontSize: 15, color: "red" }}>
                  {rePasswordError}
                </div>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant='gradient'
                color='info'
                onClick={onResetPassword}
                fullWidth
              >
                request
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='text'>
                Are you sure you want to cancel resetting new password?{" "}
                <MDTypography
                  component={Link}
                  to='/login'
                  variant='button'
                  color='info'
                  onClick={onSignin}
                  fontWeight='medium'
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;