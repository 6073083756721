import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/table";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getStatus } from "api/dashboard";
import { loadUser } from "api/auth";
import { Tabs } from 'antd';
import { useMaterialUIController } from "context";
import { getRoles, getUsers, getAdminTradeLinks, getSystemTrades } from "api/admin";
import Trades from "./components/trades";
import TradeCopy from "./components/trade";
import ControlPanel from "./components/ControlPanel";
import DashboardTab from "./components/dashboard";


function Dashboard({ getStatus, loadUser, getRoles, user, getUsers, getAdminTradeLinks, getSystemTrades }) {
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const items = [
    {
      key: '5',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Dashboard</h1>,
      children: <DashboardTab darkMode={darkMode}/>,
    },
    {
      key: '1',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Users</h1>,
      children: <DataTable />,
    },
    {
      key: '2',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>TradeLinks</h1>,
      children: <TradeCopy />,
    },
    {
      key: '4',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Trades Page</h1>,
      children: <Trades />,
    },
    {
      key: '3',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Control Panel</h1>,
      children: <ControlPanel />,
    },
  ];

  const clickTab = (key) => {
    if (user?.isSubscribed) {
      if (key === '1') {
        getStatus();
        getUsers();
      }else if (key === '2'){
        getAdminTradeLinks();
      }else if (key === '4'){
        getSystemTrades(1);
      }
    }
  }

  useEffect(()=> {
    getStatus();
    getRoles();
    if (user === null) {
      loadUser();
    }
  }, [getStatus, loadUser, getRoles, user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Tabs onTabClick={clickTab} defaultActiveKey="5" items={items} />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  statusInfo: state.dashboard?.statusInfo,
  users: state.admin?.users,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getStatus, loadUser, getRoles, getUsers, getAdminTradeLinks, getSystemTrades })(Dashboard);
