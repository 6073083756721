import axiosHelper from "../Utilities/axiosHelper";
import {
  GET_USERS,
  GET_ROLES,
  GET_ALLTRADES,
  GET_SYSTEMTRADES
} from "./types";
import { API_ENDPOINTS } from "../apiConfig";


const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true, 
  timeZone: userTimezone 
};

export const getUsers = () => async (dispatch) => {
  try {
      let response = await axiosHelper.get(API_ENDPOINTS.admin.users);
      if (response.status === 200) {
        let data = response.data;
        for (let i = 0; i < data.rows.length; i++) {
          const utcTime = data.rows[i]['lastLoginDate'];
          const utcTime1 = data.rows[i]['created'];
          const date = new Date(utcTime);
          const date1 = new Date(utcTime1);
          const formattedDate = new Intl.DateTimeFormat('default', options).format(date);
          const formattedDate1 = new Intl.DateTimeFormat('default', options).format(date1);
          data.rows[i]['lastLoginDate'] = formattedDate;
          data.rows[i]['created'] = formattedDate1;
        }
        dispatch({
            type: GET_USERS,
            payload: data,
        });
      }else{
        return {status: response.status, message: response.message};
      }
  } catch (err) {
  }
};

export const getRoles = () => async (dispatch) => {
  try {
      let response = await axiosHelper.get(API_ENDPOINTS.admin.roles);
      if (response.status === 200) {
          dispatch({
            type: GET_ROLES,
            payload: response.data,
          });
      }
      return response;
  } catch (err) {
  }
};

export const getAdminTradeLinks = () => async (dispatch) => {
  try {
    var tradeLink = (
      await axiosHelper.get(API_ENDPOINTS.admin.tradeLinks)
    );


    if (tradeLink.status === 200) {
      dispatch({
        type: GET_ALLTRADES,
        payload: tradeLink.data,
      });
    }
    return tradeLink;
  } catch (err) {
    console.error(err);
  }
};

export const getSystemTrades = (page) => async (dispatch) => {
  try {
    let url = API_ENDPOINTS.admin.systemTrades + "?pageNumber=" + page;
    var trades = (
      await axiosHelper.get(url)
    );
    
    if (trades.status === 200) {
      let data = trades.data;
      for (let i = 0; i < data.tradeRecords.length; i++) {
        const timeStamp = data.tradeRecords[i]?.['timeStamp'];
        const date = new Date(timeStamp);
        const formattedDate = new Intl.DateTimeFormat('default', options).format(date);
        data.tradeRecords[i]['timeStamp'] = formattedDate;
      }
      dispatch({
        type: GET_SYSTEMTRADES,
        payload: data,
      });
    }
    return trades;
  } catch (err) {
    console.error(err);
  }
};