import {
  PROFILE_ERROR,
  GET_PROFILES,
  GET_BROKERINFO,
  GET_PLATFORMINFO,
  GET_PLATFORMS,
  POST_PLATFORMDATA,
  GET_USER_PLATFORMS,
  NEW_PLATFORM,
  GET_PUBLICACCOUNTS
} from "../api/types";

const initalState = {
  profile: null,
  profiles: [],
  repos: [],
  loading: true,
  brokerInfo: [],
  error: {},
  platformInfo: [],
  platforms: [],
  userPlatforms: [],
  new: false,
  publicAccounts: []
};

function profile(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };
    case NEW_PLATFORM:
      return {
        ...state,
        new: !state['new'],
      }
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_BROKERINFO:
      return {
        ...state,
        brokerInfo: payload,
        loading: false,
      };
    case GET_PLATFORMINFO:
      return {
        ...state,
        platformInfo: payload,
        loading: false,
      };
    case GET_PUBLICACCOUNTS:
      return {
        ...state,
        publicAccounts: payload,
      };
    case GET_PLATFORMS:
      return {
        ...state,
        platforms: payload,
        loading: false,
      };
    case POST_PLATFORMDATA:
      return {
        ...state,
        userPlatforms: [...state.userPlatforms, payload],
        loading: false,
      };
    case GET_USER_PLATFORMS:
      return {
        ...state,
        userPlatforms: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default profile;
