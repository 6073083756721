import {
  GET_TRADELINK,
  TRADELINK_ERROR,
  TRADE_HISTORY,
  SET_CHANGE,
  SET_OPENDIA,
  ADD_TRADELINK,
  AGREE_SAVE
} from "../api/types";

const initalState = {
  error: {},
  tradeHistory: [],
  loading: true,
  tradeLink: [],
  isChange: [],
  setDlg: false,
  addFlag: false,
  agree: false,
};

function tradeLink(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TRADELINK:
      return {
        ...state,
        tradeLink: payload,
      };
    case ADD_TRADELINK:
      return {
        ...state,
        tradeLink: payload,
      };
    case SET_CHANGE:
      return {
        ...state,
        isChange: payload
      };
    case TRADE_HISTORY:
      return {
        ...state,
        tradeHistory: payload,
        loading: false,
      };
    case SET_OPENDIA:
      return {
        ...state,
        setDlg: payload,
      };
    case AGREE_SAVE:
      return {
        ...state,
        agree: payload,
      };
    case TRADELINK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    
    default:
      return state;
  }
}

export default tradeLink;