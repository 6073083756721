import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { API_ENDPOINTS } from "../../apiConfig";
import axios from 'axios';
import { connect } from "react-redux";
import { loadUser } from "../../api/auth";
import { setTokenExpirationTime } from "../../Utilities/authUtility";
import axiosHelper from "../../Utilities/axiosHelper";

const TokenValidation = ({loadUser, user}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const userId = queryParams.get('userId');
  if (token === '' || token === null || userId === '' || userId === null) {
    return <Navigate to="/signin" replace />;
  }

  const validateRequest = async(url) => {
    try {
      await axiosHelper.get(url)
        .then(response => {
          if (response.status === 200) {
            if (response.csrfToken) {
              localStorage.setItem("csrfToken", response.csrfToken);
          }
          setTokenExpirationTime()
            if (user === null) {
              loadUser();
            }
            return true;
          }else{
            return false;
          }
        })
        .catch(error => {
          console.error('Error fetching data', error);
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  let url = API_ENDPOINTS.user.validateToken + "?token=" + token + "&userId=" + userId;
  if(validateRequest(url)){
    return <Navigate to="/dashboard" replace />
  }else{
    return <Navigate to="/signin" replace />
  }

};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {loadUser})(TokenValidation);
