// authUtils.js

export const isTokenExpired = () => {
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  if (!tokenExpiration) return true; // If there's no expiration time, consider it expired

  // Ensure the stored expiration time is a number and compare it with the current time
  return Date.now() >= Number(tokenExpiration);
};

export const setTokenExpirationTime = () => {
  // Set expiration time to 30 minutes from now in milliseconds (UTC)
  const expirationTime = Date.now() + (30 * 60 * 1000); // 30 minute
  localStorage.setItem('tokenExpiration', expirationTime.toString()); // Store it as a string
};
