import React, { useEffect, useState } from "react";
import axiosHelper from "../../../Utilities/axiosHelper";
import { connect } from "react-redux";
import {
  getPlatforms,
  getBrokerInfo,
  postPlatformData,
  getUserPlatforms,
  newPlatform
} from "../../../api/profile";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Radio,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { API_ENDPOINTS } from "apiConfig";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMaterialUIController } from "context";

function PlatformInfoFormComponent({
  getPlatforms,
  getBrokerInfo,
  platforms,
  brokerInfo,
  setSuccessSB,
  setErrorSB,
  setContent,
  userPlatforms,
}) {

  useEffect(() => {
    getPlatforms();
    getBrokerInfo();
  }, [getPlatforms, getBrokerInfo]);

  return (
    <div>
      <FormCard1
        platforms={platforms}
        brokerInfo={brokerInfo}
        setSuccessSB={setSuccessSB}
        setErrorSB={setErrorSB}
        setContent={setContent}
        userPlatforms={userPlatforms}
      />
    </div>
  );
}



function FormCard({
  platforms,
  brokerInfo,
  setSuccessSB,
  setErrorSB,
  setContent,
  userPlatforms,
  newPlatform,
  getUserPlatforms,
  user
}) {
  const [formData, setFormData] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [requiredFields, setRequiredFields] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const canPublicAccounts = ['MetaTrader4', 'MetaTrader5', 'WebHook'];

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedPlatform(value);
    checkFieldFilled("selectedPlatform", value);

    const platformOptions =
      platforms.find((p) => p.label === value)?.options || [];
    const newFormData = {};
    const newRequiredFields = {};
    platformOptions.forEach((option) => {
      newFormData[option.name] = "";
      newRequiredFields[option.name] = false; 
    });
    setRequiredFields(newRequiredFields);
  };

  const handleChange = (optionName, value) => {
    setFormData((prev) => ({ ...prev, [optionName]: value }));
    checkFieldFilled(optionName, value);
  };

  const allRequiredFieldsFilled = () => {
    const accountFieldsFilled =
      formData.AccountName &&
      formData.AccountName.trim() !== "" &&
      formData.AccountId &&
      formData.AccountId.trim() !== "";
    return (
      accountFieldsFilled &&
      Object.values(requiredFields).every((filled) => filled)
    );
  };

  const checkFieldFilled = (fieldName, value) => {
    setRequiredFields((prev) => ({
      ...prev,
      [fieldName]: !!value,
    }));
  };

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { AccountId, AccountName, Demo, IsPublic, ...otherDetails } = formData;
    const brokerData = brokerInfo?.find(
      (broker) => broker.label === selectedPlatform
    );
    const brokerId = brokerData ? brokerData.value : null;

    if (!brokerId) {
      return;
    }
    for (let i = 0; i < userPlatforms.length; i++) {
      if(AccountName === userPlatforms[i].accountName){
        setContent("Account Name must be unique !");
        setErrorSB(true);
        return;
      }
      if(AccountId === userPlatforms[i].accountId) {
        setContent("Account Id must be unique!");
        setErrorSB(true);
        return;
      }
    }

    let tempDetails = otherDetails;
    tempDetails.Demo = Demo;
    
    if (AccountId === tempDetails?.AccountNumber) {
      setContent("Account ID and Account Number must be different !");
      setErrorSB(true);
      return;
    }

    const postData = {
      platformId: brokerId,
      accountId: AccountId,
      accountName: AccountName,
      isPublic: IsPublic === "true",
      platformAccountInfoJson:
        Object.keys(tempDetails).length === 0
          ? ""
          : JSON.stringify(tempDetails),
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axiosHelper.post(
        API_ENDPOINTS.profileEndpoints.postPlatformData,
        postData,
        config
      );
      if (response.status === 200) {
        setContent("Platform connected");
        setSuccessSB(true);
        getUserPlatforms();
        newPlatform();
      }else{
        setContent(response.message);
        setErrorSB(true);
      }
    } catch (err) {
      setContent("Error connecting platform");
      setErrorSB(true);
    }
  };

  if (platforms == null || platforms.length === 0) {
    return <Typography>Getting platform data</Typography>;
  }

  const renderFormFields = (options) => {
    return options.map((option, index) => {
      if (option.required) {
        if (requiredFields[option.name] === undefined) {
          checkFieldFilled(option.name, formData[option.name] || false);
        }
      }

      switch (option.uiComponentType) {
        case "Input":
          if (option.name === "Password") {
            return (
              <TextField
                key={index}
                fullWidth
                margin='normal'
                label={option.name}
                required={option.required}
                inputProps={{ maxLength: 50 }}
                type={showPassword ? "text" : "password"}
                value={formData[option.name] || ""}
                onChange={(e) => handleChange(option.name, e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: textColor }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          } else {
            return (
              option.name === "MatchTraderUrl" ?
              <TextField
                type="text"
                key={index}
                fullWidth
                margin='normal'
                label={option.name}
                required={option.required}
                value={formData[option.name] || ""}
                onChange={(e) => handleChange(option.name, e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={!!error}
                helperText={error || <p style={{color: darkMode? "white" : "black"}}>Enter a valid .com domain</p>}
              />
              :
              <TextField
                type="text"
                key={index}
                fullWidth
                margin='normal'
                label={option.name}
                required={option.required}
                value={formData[option.name] || ""}
                onChange={(e) => handleChange(option.name, e.target.value)}
                inputProps={{ maxLength: 50 }}
              />
            );
          }
        case "Select":
          return (
            <FormControl fullWidth margin='normal' key={index}>
              <InputLabel>{option.name}</InputLabel>
              <Select
                value={formData[option.name] || ""}
                onChange={(e) => handleChange(option.name, e.target.value)}
                label={option.name}
                inputProps={{ maxLength: 50 }}
                required={option.required}
              >
                {option.options?.map((opt, i) => (
                  <MenuItem key={i} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
          case "Radio":
            return (
              <FormControl item component="fieldset" key={index} margin="normal">
                <Grid container className="items-center">
                  <FormLabel item component="legend" style={{ fontSize: 15 }}>{option.name}</FormLabel>
                  <RadioGroup
                    item
                    row
                    className="ml-10"
                    value={formData[option.name] || ""}
                    onChange={(e) => handleChange(option.name, e.target.value)}
                  >
                    {option.options.map((opt) => (
                      <FormControlLabel
                        key={opt}
                        value={opt}
                        control={<Radio aria-required= {"true"} />}
                        label={opt.replace(' *', '')} // Remove asterisk from the label
                        style={{ display: 'inline-flex', marginRight: '16px' }}
                      />
                    ))}
                  </RadioGroup>   
                </Grid>
              </FormControl>
            );            
        default:
          return null;
      }
    });
  };

  const baseOptions =
    platforms.find((p) => p.label === "baseOptions")?.options[0].options || [];

  const selectedOptions =
    platforms.find((p) => p.label === selectedPlatform)?.options || [];

  return (
    <div>
      <Card sx={{ height: "100%" }}>
        <CardContent padding='1rem'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin='normal'>
                  <InputLabel>Platform</InputLabel>
                  <Select
                    value={selectedPlatform}
                    onChange={handleSelectChange}
                    label='Platform'
                    className='w-full'
                    style={{ height: 40 }}
                    id="selectPlatform"
                    input={<OutlinedInput label='Platform' />}
                    endAdornment={
                      <InputAdornment position='end'>
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  >
                    {baseOptions.map((platform) => (
                      <MenuItem key={platform} value={platform}>
                        {platform}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedPlatform && (
                  <>
                    <TextField
                      fullWidth
                      margin='normal'
                      label='AccountName'
                      required
                      value={formData.AccountName || ""}
                      inputProps={{ maxLength: 50 }}
                      id="accountName"
                      onChange={(e) =>
                        handleChange("AccountName", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      margin='normal'
                      label='AccountId'
                      required
                      value={formData.AccountId || ""}
                      inputProps={{ maxLength: 50 }}
                      id="accountId"
                      onChange={(e) =>
                        handleChange("AccountId", e.target.value)
                      }
                    />
                    {(selectedPlatform && user?.roles?.includes(3) && canPublicAccounts.includes(selectedPlatform)) && 
                      <FormControl item component="fieldset" key="IsPublic" margin="normal">
                        <Grid container className="items-center">
                          <FormLabel item component="legend" style={{ fontSize: 15 }}>Public Account</FormLabel>
                          <RadioGroup
                            item
                            row
                            className="ml-10"
                            value={formData.IsPublic || false}
                            onChange={(e) => handleChange('IsPublic', e.target.value)}
                          >
                              <FormControlLabel
                                key="true"
                                value={true}
                                control={<Radio aria-required= {"true"} />}
                                label="true"
                                style={{ display: 'inline-flex', marginRight: '16px' }}
                              />
                              <FormControlLabel
                                key="false"
                                value={false}
                                label="false"
                                control={<Radio aria-required= {"false"} />}
                                style={{ display: 'inline-flex', marginRight: '16px' }}
                              />
                          </RadioGroup>   
                        </Grid>
                      </FormControl>
                    }
                    <br/>
                  </>
                )}
                {selectedPlatform && renderFormFields(selectedOptions)}

                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  id="submitNewPlatform"
                  style={{ mt: 2, color: "white", marginBottom: "20px" }}
                  disabled={!selectedPlatform || !allRequiredFieldsFilled()}
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export const FormCard1 = connect(
  (state) => ({
    user: state.auth?.user,
  })
  , {newPlatform, getUserPlatforms})(FormCard);

export default connect(
  (state) => ({
    userPlatforms: state.profile.userPlatforms,
    platforms: state.profile.platforms,
    brokerInfo: state.profile.brokerInfo, 
    user: state.auth?.user,
  }),
  { getPlatforms, getBrokerInfo, postPlatformData }
)(PlatformInfoFormComponent);
