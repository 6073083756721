import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Header from "./components/Header";
import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import linkActive from "assets/images/link_active.png";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import linkDisable from "assets/images/link_disable.png";
import linkError from "assets/images/link_error.png";
import { connect, useDispatch } from "react-redux";
import { getBrokerInfo } from "api/profile";
import PropTypes from "prop-types";
import {Input, InputLabel } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useMaterialUIController } from "context";
import TradeLinkErrorModal from "./components/TradeLinkErrorModal";
import axiosHelper from 'Utilities/axiosHelper.js'
import "./index.css";
import { loadUser } from "api/auth";
import { getAdminTradeLinks } from "api/admin";
import { API_ENDPOINTS } from "apiConfig";

const Trade = ({
  link,
  error,
  user
}) => {
  const dispatch = useDispatch();

  const [errorModal, setErrorModal] = React.useState(false);

  const [hovered, setHovered] = useState(false);


  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='TradeCopy'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='TradeCopy'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color='warning'
      icon='star'
      title='TradeCopy'
      content={content}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='TradeCopy'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  if (Object.keys(error).length !== 0) {
    setContent(error.msg);
    setErrorSB(true);
  }

  const closeErrorModal = () => {
    setErrorModal(false);
  }

  const errorOpen = () => {
    setErrorModal(true);
  }

  const [tradeInfo, setTradeInfo] = useState({
    id:'',
    traderAccountName: '',
    copyCatAccountName: '',
    isActive: false,
    errorMessage: null,
    errorResolved: false
  });

  useEffect(() => {
    setTradeInfo(prev => ({
      id:link?.id || 'add',
      traderAccountName: link?.traderAccountName || '',
      copyCatAccountName: link?.copyCatAccountName || '',
      isActive: link?.isActive,
      errorMessage: link?.errorMessage,
      errorResolved: link?.errorResolved,
    }));
  }, [link]);
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  
  const onActiveTradeLink = async() => {
    let url = API_ENDPOINTS.admin.activeLinks + "?tradeAccountLinkId=" + tradeInfo.id + "&activateTradeLink=" + !tradeInfo.isActive;
    let res = await axiosHelper.get(url);
    if (res.status === 200 || res.status === 204) {
      setContent('Status of link set to ' + !tradeInfo.isActive);
      setSuccessSB(true);
      dispatch(getAdminTradeLinks());
    }else{
      setContent(res.message);
      setErrorSB(true);
    }
  }

  let selectStyle = null;
  return (
    <div>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      {renderInfoSB}
      <TradeLinkErrorModal open={errorModal} setOpen={closeErrorModal} errorMessage={link.errorMessage} createdDate={link.createdDate? link.createdDate : "2024-05-15"} updatedDate={link.updatedDate? link.updatedDate : "2024-05-15"} tradeLinkId={link.id} userId={link.userId} traderPlatform={link.traderPlatform} copyCatPlatform={link.copyCatPlatform} email={link.email} CopyCatAccountId={link.copyCatPlatformAccountId} TraderAccountID={link.traderPlatformAccountId} />
      <Grid container spacing={1} className='p-4 justify-center sm:p-[1px]'>
        <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
          <FormControl variant='standard' className='w-full'>
          <InputLabel>Trader</InputLabel>
            <Input
              value={tradeInfo?.traderAccountName}
              readOnly
              displayempty={true.toString()}
              sx={selectStyle}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
          <div className='flex w-full h-full'>
            {
              user?.isSubscribed?
              (tradeInfo?.errorResolved || tradeInfo.errorMessage === null) ? 
                (
                  tradeInfo.isActive ? 
                  <img src={linkActive} alt='Share' className='w-9 m-auto sm:w-6' onClick={onActiveTradeLink} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }}/>
                  : 
                  <img src={linkDisable} alt='Share' className='w-9 m-auto sm:w-6' onClick={onActiveTradeLink} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }} />
                )
                :
                (
                  <img src={linkError} alt='Share' className='w-9 m-auto sm:w-6' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }} />
                )
              :
              (<img src={linkDisable} alt='Share' className='w-9 m-auto sm:w-6' onClick={onActiveTradeLink}  onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }}/>)
            }
          </div>
        </Grid>
        <Grid item xs={5} md={5} xl={5} sx={{ display: "flex" }}>
          <FormControl variant='standard' className='w-full'>
          <InputLabel>Copy Cat</InputLabel>
          <Input
            value={tradeInfo?.copyCatAccountName}
            readOnly
            displayempty={true.toString()}
            sx={selectStyle}
          />
          </FormControl>
        </Grid>
        <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
          <div className='m-auto w-1/5 sm:w-1/6'>
            <DeleteOutlineIcon
              sx={{
                width: '30px',
                height: '30px',
                color: darkMode? "#ffffff" : null,
                  "&:hover": {
                    width: '35px',
                    height: '35px'
                  },
                  '@media (min-width:200px)': {
                    width: '17px',
                    height: '17px',
                    '&:hover': {
                      width: '20px',
                      height: '20px',
                    },
                  },
                  '@media (min-width:500px)': {
                    width: '25px',
                    height: '25px',
                    '&:hover': {
                      width: '30px',
                      height: '30px',
                    },
                  },
                  '@media (min-width:800px)': {
                    width: '30px',
                    height: '30px',
                    '&:hover': {
                      width: '35px',
                      height: '35px',
                    },
                  },
              }}/>
          </div>
          
          {(tradeInfo?.errorResolved || tradeInfo.errorMessage === null) ? 
            (
              <div className='m-auto w-1/5 sm:w-1/6' onClick={errorOpen}>
                <SearchIcon 
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: darkMode? "#ffffff" : null,
                    "&:hover": {
                      width: '35px',
                      height: '35px'
                    },
                    '@media (min-width:200px)': {
                      width: '17px',
                      height: '17px',
                      '&:hover': {
                        width: '20px',
                        height: '20px',
                      },
                    },
                    '@media (min-width:500px)': {
                      width: '25px',
                      height: '25px',
                      '&:hover': {
                        width: '30px',
                        height: '30px',
                      },
                    },
                    '@media (min-width:800px)': {
                      width: '30px',
                      height: '30px',
                      '&:hover': {
                        width: '35px',
                        height: '35px',
                      },
                    },
                  }}/>
              </div>
            ) :
            (
              <div className='m-auto w-1/5 sm:w-1/6 icon pulsing1 relative' onClick={errorOpen}>
                <SearchIcon
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: 'red',
                      "&:hover": {
                        width: '35px',
                        height: '35px'
                      },
                      '@media (min-width:200px)': {
                        width: '17px',
                        height: '17px',
                        '&:hover': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                      '@media (min-width:500px)': {
                        width: '25px',
                        height: '25px',
                        '&:hover': {
                          width: '30px',
                          height: '30px',
                        },
                      },
                      '@media (min-width:800px)': {
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                          width: '35px',
                          height: '35px',
                        },
                      },
                  }} />
              </div>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};

const TradeList = ({ links }) => {
  let tradeLinks = links || [];
  if (tradeLinks.length === 0) {
    tradeLinks.length = 0;
    tradeLinks.push({
      id: "add",
      traderAccountName: '',
      copyCatAccountName: '',
      settings: {
        managmentType: 0,
        fixedTp: "",
        fixedSl: "",
        riskValue: "",
        pairSuffix: ""
      },
      isActive: false,
      errorMessage: null
    });
  }

  return (
    <MDBox>
      {[...tradeLinks].reverse().map((link, index) => (
        <TradeLink link = {link} key={link.id} />        
      ))}
    </MDBox>
  );
};

function TradeCopy({ tradeLink, tradeInfo }) {

  return (
    <>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="dark"
              icon='active_link'
              title="Number of Links"
              count={tradeInfo?.totalLinks}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="success"
              icon="active_link"
              title="Active Links"
              count={tradeInfo?.totalActiveLinks}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="success"
              icon="percent"
              title="Active to Inactive Ratio"
              count={tradeInfo?.totalActiveLinks}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Header sx={{ width: '100%', borderRadius: 4, overflow: "hidden" }}>
        <MDBox mb={3} mt={3}>
          <TradeList links={tradeLink} key={1}/>
        </MDBox>
      </Header>
    </>
  );
}

TradeCopy.propTypes = {
  getBrokerInfo: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  error: state.tradeLink?.error,
  tradeLink: state.admin?.allTrades || [],
  tradeInfo: state.admin?.tradeInfo,
  user: state.auth?.user
});

export const TradeLink = connect(mapStateToProps, { getBrokerInfo, getAdminTradeLinks })(Trade);
export default connect(mapStateToProps, { getBrokerInfo, loadUser })(TradeCopy);