export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_BROKERINFO = "GET_BROKERINFO";
export const GET_PLATFORMINFO = "GET_PLATFORMINFO";
export const GET_PLATFORMS = "GET_PLATFORMS";
export const GET_USER_PLATFORMS = "GET_USER_PLATFORMS";
export const POST_PLATFORMDATA = "POST_PLATFORMDATA";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PLATFORM_ERROR = "PLATFORM_ERROR";
export const GET_TRADELINK = "GET_TRADELINK";
export const TRADELINK_ERROR = "TRADELINK_ERROR";
export const NEW_PLATFORM = "NEW_PLATFORM";
export const TRADE_HISTORY = "TRADE_HISTORY";
export const SET_CHANGE = "SET_CHANGE";
export const SET_OPENDIA = "SET_OPENDIA";
export const INFO_PAGE = "INFO_PAGE";
export const GET_STATUS = "GET_STATUS";
export const ADD_TRADELINK = "ADD_TRADELINK";
export const AGREE_SAVE = "AGREE_SAVE";
export const GET_USERS = "GET_USERS";
export const GET_ROLES = "GET_ROLES";
export const GET_ALLTRADES = "GET_ALLTRADES";
export const RESET_STATE = "RESET_STATE";
export const GET_SYSTEMTRADES = "GET_SYSTEMTRADES";
export const GET_PUBLICACCOUNTS = "GET_PUBLICACCOUNTS";