import React from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import { Typography } from "antd";
const { Text } = Typography;


const TokenModal = ({ open, setOpen, token }) => {
  const handleClose = () => {
    setOpen(false);
  }; 
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', color: darkMode? 'white' : 'black' }}>Token</h3>}
      centered
      open={open}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      footer={[]}
    >
    <Text style={{ color: darkMode? 'white' : 'black' }}>{token}</Text>
    </Modal>
  );
};

export default TokenModal;
