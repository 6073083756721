import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "./components/table";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getStatus } from "api/dashboard";
import { loadUser } from "api/auth";

function Dashboard({ getStatus, statusInfo, loadUser, user }) {
  useEffect(()=> {
    if (user === null) {
      loadUser();
    }
    getStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatus, loadUser]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon='active_link'
                title="Active Links"
                count={statusInfo?.activeTradeLinks}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="table_view"
                title="Monthly Trades Copied"
                count={statusInfo?.tradesCopiedThisMonth}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="percent"
                title="Monthly Percentage Gain"
                count={statusInfo?.monthlyProfitGain}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox className="mt-6">
          <Grid container spacing={3} className="pl-6 ">
            <DataTable/>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
  statusInfo: state.dashboard?.statusInfo
});

export default connect(mapStateToProps, { getStatus, loadUser })(Dashboard);
