import {
    GET_USERS,
    GET_ROLES,
    GET_ALLTRADES,
    GET_SYSTEMTRADES
  } from "../api/types";
  
  const initalState = {
    users: [],
    roles: [],
    userData: null,
    allTrades: [],
    tradeInfo: null,
    systemTrades: [],
    trades: null,
  };
  
  export default function admin(state = initalState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_USERS:
          return {
            ...state,
            users: payload.rows,
            userData: payload
          };
        case GET_SYSTEMTRADES:
          return {
            ...state,
            systemTrades: payload.tradeRecords,
            trades: payload
          };
        case GET_ROLES:
          return {
            ...state,
            roles: payload
          };
        case GET_ALLTRADES:
          return {
            ...state,
            allTrades: payload.rows,
            tradeInfo: payload
          };
        default:
            return state;
    }
  }