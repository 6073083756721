import axiosHelper from "../Utilities/axiosHelper";
import {
  INFO_PAGE
} from "./types";
import { API_ENDPOINTS } from "../apiConfig";

export const getServerInfo = () => async (dispatch) => {
    try {
        let response = await axiosHelper.get(API_ENDPOINTS.health.version);
        if (response.status === 200) {
            dispatch({
              type: INFO_PAGE,
              payload: {
                beVersion: response.data,
                beEnabled: true,
              },
            });
        }else{
            dispatch({
              type: INFO_PAGE,
              payload: {
                beVersion: "",
                beEnabled: false,
              },
            });
        }
    } catch (err) {
    }
};