import "./modal.css";
import React from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import { Typography } from "antd";
const { Text } = Typography;

const TradeLinkErrorModal = ({ open, setOpen, errorMessage, createdDate, updatedDate, tradeLinkId, traderPlatform, copyCatPlatform, userId, email, CopyCatAccountId, TraderAccountID }) => {

  const formatDate = (dateString) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcTime = dateString;
    const date = new Date(utcTime);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, 
      timeZone: userTimezone 
    };
    const formattedDate = new Intl.DateTimeFormat('default', options).format(date);
    
    return formattedDate;
  }

  const handleClose = () => {
    setOpen(false);
  }; 
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', fontSize: 24, color: textColor }}>Trade Link details</h3>}
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      width={700}
      footer={[
        <div className="w-full flex justify-between">
          <Text type="secondary" style={{ color: textColor }}>Created Date: {formatDate(createdDate)}</Text>
          <Text type="secondary" style={{ color: textColor }}>Updated Date: {formatDate(updatedDate)}</Text>
        </div>
      ]}
    >
      <div className="mb-[20px]">
        <Text style={{ fontSize: 18, color: textColor }}>User ID: {userId}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>TradeLink ID: {tradeLinkId}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>TraderPlatform: {traderPlatform}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>CopyCatPlatform: {copyCatPlatform}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>Email: {email}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>CopyCatAccountId: {CopyCatAccountId}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>TraderAccountID: {TraderAccountID}</Text>
      </div>
        <Text style={{ color: textColor }}>{errorMessage}</Text>
    </Modal>
  );
};

export default TradeLinkErrorModal;