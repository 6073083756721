import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import defaultBackgroundImage  from "assets/images/fxbackground.png";
import { Tabs } from 'antd';
import ChangeLog from "../ChangeLog";
import Version from "../Version";
import { useMaterialUIController } from "context";

function Header({ children ,backgroundImage}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const items = [
    {
      key: '1',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>General Info</h1>,
      children: 
        <>
          {children}
        </>
    },
    {
      key: '2',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Change Log</h1>,
      children: <ChangeLog />,
    },
    {
      key: '3',
      label: <h1 style={{ color: darkMode? 'white' : 'black', fontSize:'19px', fontWeight: 500 }}>Version</h1>,
      children: <Version />,
    }
  ];

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.2),
              rgba(gradients.info.state, 0.2)
            )},  url(${backgroundImage || defaultBackgroundImage})`,
          backgroundSize: "cover", 
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: "4px",
          '@media (min-width:200px)': {
            mx: 0
          },
          '@media (min-width:500px)': {
            mx: 1
          },
        }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
  backgroundImage: null,
};

Header.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
};

export default Header;
