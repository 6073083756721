import * as React from "react";
import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { getServerInfo } from "api/infoPage";
import { connect } from "react-redux";
import headerBackgroundImage  from "assets/images/info.png";
import { loadUser } from "api/auth";
import tradeLocker from "assets/images/tradeLocker.jpg";
import matchTrader from "assets/images/matchTrader.jpg";
import tradingView from "assets/images/tradingView.jpg";
import metaTrader4 from "assets/images/metaTrader4.jpg";
import metaTrader5 from "assets/images/Meta-Trader5.jpg";
import { useMaterialUIController } from "context";

function Version({ getServerInfo, serverInfo, loadUser, user }) {

  const [feVersion, setFeVersion] = useState('');

  React.useEffect(() => {
    getServerInfo();
    if (user === null) {
      loadUser();
    }
  },[getServerInfo, loadUser, user]);

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  useEffect(() => {
    const getVersionFromCache = async () => {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        const cache = await caches.open(cacheName);
        const response = await cache.match('version');
        if (response) {
          const version = await response.text();
          setFeVersion(version);
          break;
        }
      }
    };
    
    getVersionFromCache();
    getServerInfo();
  }, [getServerInfo]);
  
  return (
    <div>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} className='p-4 justify-center'>
            <Grid item xs={10} md={4} xl={4} sx={{ display: "flex" }}>
              <Typography id="transition-modal-title" variant="h4" component="h2">
                FE Version:  {feVersion}
              </Typography>
            </Grid>
            <Grid item xs={10} md={4} xl={4} sx={{ display: "flex" }}>
              <Typography id="transition-modal-title" variant="h4" component="h2">
                BE Version:  {serverInfo?.beVersion}
              </Typography>
            </Grid>
            <Grid item xs={10} md={4} xl={4} sx={{ display: "flex" }}>
              <Typography id="transition-modal-title" variant="h4" component="h2">
                BE Enabled:
              </Typography>
              {serverInfo?.beEnabled ?
                <div className='w-1/4 ml-3'>
                  <CircleIcon disabled style={{ width: 30, height: 30, color: '#00dc00' }}/>
                </div>
                :
                <div className='w-1/4 ml-3'>
                  <CircleIcon disabled style={{ width: 30, height: 30, color: 'red' }}/>
                </div>
              }
            </Grid>
          </Grid>
      </MDBox>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
  serverInfo: state.infoPage?.serverInfo
});

export default connect(mapStateToProps, { getServerInfo, loadUser })(Version);
