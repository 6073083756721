import {
    INFO_PAGE
  } from "../api/types";
  
  const initalState = {
    serverInfo: null,
  };
  
  export default function infoPage(state = initalState, action) {
    const { type, payload } = action;

    switch (type) {
        case INFO_PAGE:
          return {
            ...state,
            serverInfo: payload,
          };
        default:
            return state;
    }
  }