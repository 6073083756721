import axios from 'axios';
import { API_ENDPOINTS } from '../apiConfig';
import { isTokenExpired, setTokenExpirationTime } from "./authUtility";
import Cookies from 'js-cookie'; // Use js-cookie to handle cookies (optional)

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, 
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // Important: This sends the cookies with each request
});

let isRefreshing = false;
let subscribers = [];

// Function to add subscribers (pending requests)
const subscribeTokenRefresh = (cb) => {
  subscribers.push(cb);
};

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
  const event = new CustomEvent('localStorageChanged', {
    detail: { key, value },
  });
  window.dispatchEvent(event);
};

const onRrefreshed = () => {
  subscribers.forEach((cb) => cb());
  subscribers = []; // Clear the subscribers after calling them
};

const getNewSessionToken = async () => {
  const response = await axios.post(
    API_ENDPOINTS.baseEndpoints.refresh,
    {},
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }
  );

  setTokenExpirationTime();
  onRrefreshed(); // Notify all subscribers once the refresh is done
  return response.data;
};


// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Only add CSRF token for PUT, POST, DELETE requests
    if (['put', 'post', 'delete'].includes(config.method.toLowerCase())) {
      let csrfToken = ""
      if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') 
      {
        csrfToken = localStorage.getItem("csrfToken");
      }
       else
        {
          csrfToken =  Cookies.get('X-CSRF-Token');
        }
       

      if (csrfToken) 
        config.headers['X-CSRF-Token'] = csrfToken;
       else
          console.warn("CSRF Token not found in cookies");
      
      }
      
      if (
        config.url.includes('/login') ||
        config.url.includes('/refresh') ||
        config.url.includes('/signup')||
        config.url.includes('/logout')||
        config.url.includes('/resend-verification')||
        config.url.includes('/forgot-password')||
        config.url.includes('/reset-password')||
        config.url.includes('/validate-token')||
        config.url.includes('/track-click')||
        !isTokenExpired()
      ) {
        return config;
      }

    if (!isRefreshing) {
      isRefreshing = true;
      try {
        await getNewSessionToken();  // We no longer need to return the token, just refresh it
        isRefreshing = false;
        return config;  // Now the cookies will be sent automatically
      } catch (error) {
        isRefreshing = false;
        setLocalStorage("isExpired", true);
        return Promise.reject(error);
      }
    }

    // If token is being refreshed, return a promise that resolves once it's done
    return new Promise((resolve, reject) => {
      subscribeTokenRefresh(() => {
        resolve(config);  // No need for a new token, cookies are already refreshed
      });
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle specific status codes if needed
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);

      // Optional: Handle other status codes here

      // For 401, you might not need to do anything if the request interceptor handles it
      if (error.response.status === 401) {
        setLocalStorage("isExpired", true);
      } 
      

      return Promise.reject(error.response.data);
    } else if (error.request) {
      console.error('Error request:', error.request);
      return Promise.reject({ status: 400, message: error.message });
    } else {
      console.error('Error message:', error.message);
      return Promise.reject({ status: 400, message: error.message });
    }
  }
);

export default axiosInstance;