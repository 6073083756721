import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from "react";
import { Typography } from "@mui/material";

export const ThumbnailAccordion = ({ tutorials, darkMode }) => {
  const [open, setOpen] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState({});

  const handleOpen = (index) => setOpen(open === index ? 0 : index);

  const handleThumbnailClick = (accordionIndex, videoIndex) => {
    setVideoLoaded((prev) => ({
      ...prev,
      [accordionIndex]: videoIndex,
    }));
  };

  const videoStyle = {
    width: '60%',
    height: '200px', // Adjust height as needed to match the thumbnail
  };

  const iframeClassName = "w-full md:w-[50%] lg:h-full md:h-[300px] h-[200px] ";
  return (
    <div>
      {tutorials.map((tutorial, accordionIndex) => (
        <Accordion
          key={`download_tutorial_${accordionIndex}`}
          defaultExpanded={open === accordionIndex + 1}
          sx={{
            backgroundColor: darkMode ? '#1e1e2f' : '#f5f5f5', // Adjusted to match dark theme
            color: darkMode ? '#ffffff' : '#000000',
            border: darkMode ? '1px solid #2c2c3d' : '1px solid #ddd',
            borderRadius: '8px', // Rounded corners for a similar Tailwind look
            marginBottom: '10px', // Space between accordions
            '&:hover': {
              backgroundColor: darkMode ? '#292938' : '#e0e0e0', // Subtle hover effect
            },
            '&:before': {
              display: 'none', // Remove default divider line
            },
            '& .MuiAccordionSummary-root': {
              padding: '0 16px', // Adjust padding for a compact look
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: darkMode ? '#fff' : '#000' }} />}
            aria-controls={`panel${accordionIndex + 1}-content`}
            id={`panel${accordionIndex + 1}-header`}
            onClick={() => handleOpen(accordionIndex)}
          >
            {tutorial.title}
          </AccordionSummary>
          <AccordionDetails
  sx={{
    padding: '16px',
    backgroundColor: darkMode ? '#25253a' : '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Adjusts spacing between items
    flexWrap: 'wrap', // Ensures responsiveness
    gap: '20px' // Adds space between items
  }}
>
  {tutorial.videos && tutorial.videos.length > 0 ? (
    tutorial.videos.map((video, videoIndex) => (
      <div key={video.id} style={{ flex: '1', minWidth: '300px' }}>
        {video.title && (
          <Typography variant="h6" component="p" style={{ marginBottom: '10px' }}>
            {video.title}
          </Typography>
        )}
        {videoLoaded[accordionIndex] === videoIndex ? (
          <iframe
            className={videoStyle}
            style={videoStyle}
            src={`https://www.youtube.com/embed/${video.id}`}
            loading="lazy"
            title={video.title || `Video ${videoIndex + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src={`https://img.youtube.com/vi/${video.id}/0.jpg`}
            alt={`Thumbnail for ${video.title || `Video ${videoIndex + 1}`}`}
            style={{ ...videoStyle, cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(accordionIndex, videoIndex)}
          />
        )}
      </div>
    ))
  ) : (
    <Typography variant="body2">No videos available</Typography>
  )}

  {/* Platform Links */}
  {tutorial.platforms && tutorial.platforms.length > 0 && (
    <div style={{ flex: '1', minWidth: '200px', textAlign: 'center' }}>
      {tutorial.platforms.map((platform, platformIndex) => (
        <a
          key={platformIndex}
          href={platform.linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'block', textDecoration: 'none', marginBottom: '10px' }}
        >
          <img
            src={platform.img}
            alt={platform.name || `Platform ${platformIndex + 1}`}
            className="mx-auto rounded-[2rem] p-6 w-[200px]"
          />
          <div
            className="text-white w-fit mx-auto"
            style={{ color: darkMode ? '#ffffff' : '#000000' }}
          >
            {platform.text || 'Click image to go to website to make an account'}
          </div>
        </a>
      ))}
    </div>
  )}
</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

// Export as the default export
export default ThumbnailAccordion;