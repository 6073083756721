import React, { useState } from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import { Typography } from "antd";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox/index.js";
import MDButton from "components/MDButton/index.js";
import axiosHelper from './../../../Utilities/axiosHelper.js';
import MDSnackbar from "components/MDSnackbar/index.js";
import { API_ENDPOINTS } from "apiConfig";
const { Text } = Typography;


const TokenModal = ({ open, setOpen }) => {

  const handleClose = () => {
    setToken('');
    setOpen(false);
  }; 

  const [token, setToken] = useState('');
  const [data, setData] = useState({email: localStorage.getItem('mail'), password: ''});

  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");

  const closeErrorSB = () => setErrorSB(false);

  const onChange = (e) => {
    setData({
      ...data,
      password: e.target.value,
    })
  };
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  const handleToken = async() => {
    let res = await axiosHelper.post(API_ENDPOINTS.user.getToken, data);
    if (res.status === 200) {
      setToken(res.data);
    }else{
      setContent(res.message);
      setErrorSB(true);
    }
  }

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='TradeLink Token'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', color: darkMode? 'white' : 'black' }}>Trader Token</h3>}
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} id="closeToken" />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      footer={[
        token === '' &&
        <div>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant='gradient'
              color='info'
              onClick={handleToken}
              fullWidth
              id="handleToken"
            >
              Get Token
            </MDButton>
          </MDBox>
        </div>
      ]}
    >
    {renderErrorSB}
    {token === ''?
      <MDBox mb={2}>
        <MDInput
          type='password'
          label='Password'
          fullWidth
          id='password'
          value={data["password"]}
          onChange={(e) => onChange(e)}
          required
        />
        </MDBox>
        :
        <Text style={{ color: darkMode? 'white' : 'black' }} id="tokenText">{token}</Text>
      }
    </Modal>
  );
};

export default TokenModal;
