import axiosHelper from "../Utilities/axiosHelper";
import {
  GET_STATUS
} from "./types";
import { API_ENDPOINTS } from "../apiConfig";

export const getStatus = () => async (dispatch) => {
    try {
        let response = await axiosHelper.get(API_ENDPOINTS.trade.stats);
        if (response.status === 200) {
            dispatch({
              type: GET_STATUS,
              payload: {
                tradesCopiedThisMonth: response.data.tradesCopiedThisMonth,
                monthlyProfitGain: response.data.monthlyProfitGain,
                activeTradeLinks: response.data.activeTradeLinks || 0,
              },
            });
        }
        return response;
    } catch (err) {
    }
};