import React from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import MDBox from "components/MDBox/index.js";
import MDButton from "components/MDButton/index.js";
import {Select, MenuItem } from "@mui/material";
import { connect } from "react-redux";

const RoleModal = ({ open, setOpen, id, roles, userInfo, setRole, role, setOpenDia, setMenu }) => {

  let themeStyle = null;
  let selectStyle = {
    width: "-webkit-fill-available",
    height: "40px",
  };
  const [addActive, setAddActive] = React.useState(false);
  const [removeActive, setRemoveActive] = React.useState(false);

  const user = userInfo.find((p) => p.userId === id);
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };
    selectStyle = {
      color: '#ffffff',
      width: "-webkit-fill-available",
      height: "40px",
    };
  }

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleSelectionChange = (e, child) => {
    setAddActive(!user.roles.includes(Number(child.key.split('$')[1])));
    setRemoveActive(user.roles.includes(Number(child.key.split('$')[1])));
    setRole(e.target.value);
  }

  const addRole = async() => {
    setOpenDia(true);
    setMenu(3);
  }

  const removeRole = async() => {
    setOpenDia(true);
    setMenu(2);
  }

  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', color: darkMode? 'white' : 'black' }}>User Roles</h3>}
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      footer={[
        <div>
          <MDBox mt={4} mb={1}>
            <div className="flex justify-between gap-36">
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                disabled={!removeActive}
                onClick={removeRole}
              >
                Remove Role
              </MDButton>
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                disabled={!addActive}
                onClick={addRole}
              >
                Add Role
              </MDButton>
            </div>
          </MDBox>
        </div>
      ]}
    >    
    <Select
      value={role}
      onChange={handleSelectionChange}
      displayempty={true.toString()}
      sx={selectStyle}
    >
      {
        roles?.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))
      }
    </Select>
    </Modal>
  );
};

const mapStateToProps = ((state) => ({
  userInfo: state.admin?.users,
  roles: state.admin?.roles
}))

export default connect(mapStateToProps, null)(RoleModal);
