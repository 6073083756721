import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { API_ENDPOINTS } from "apiConfig";
import axiosHelper from '../../../../Utilities/axiosHelper';
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";

function Dashboard({darkMode}) {
  const [userData, setUserData] = useState({});
  const [tradeData, setTradeData] = useState({});
  const [numberOfUniqueTraderChart, setNumberOfUniqueTraderChart] = useState({});
  const [tradeAnaly, setTradeAnaly] = useState([]);

  const getMonth = (month) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return months[month-1];
  }

  
  useEffect(()=> {
    const getChartData = async() => {
      let url = API_ENDPOINTS.admin.dashboard;
      try {
        let result = await axiosHelper.get(url);
        if (result.status === 200) {
          let data = result.data;
          let userTemp = {};
          userTemp.labels = [];
          userTemp.datasets = {};
          userTemp.datasets.labels = "new monthly users";
          userTemp.datasets.data = [];
          for (let i = 0; i < data?.userChart?.length; i++) {
            userTemp.labels.push(getMonth(data?.userChart?.[i]?.month));
            userTemp.datasets.data.push(data?.userChart?.[i]?.usersPerMonth);
          }
          let tradeTemp = {};
          tradeTemp.labels = [];
          tradeTemp.datasets = {};
          tradeTemp.datasets.labels = "monthly trades";
          tradeTemp.datasets.data = [];
          for (let i = 0; i < data?.tradeChart?.length; i++) {
            tradeTemp.labels.push(getMonth(data?.tradeChart?.[i]?.month));
            tradeTemp.datasets.data.push(data?.tradeChart?.[i]?.numberOfTrades);
          }
          let uniTradeTemp = {};
          uniTradeTemp.labels = [];
          uniTradeTemp.datasets = {};
          uniTradeTemp.datasets.labels = "unique trader";
          uniTradeTemp.datasets.data = [];
          for (let i = 0; i < data?.numberOfUniqueTraderChart?.length; i++) {
            uniTradeTemp.labels.push(getMonth(data?.numberOfUniqueTraderChart?.[i]?.month));
            uniTradeTemp.datasets.data.push(data?.numberOfUniqueTraderChart?.[i]?.numberOfUniqueTraders);
          }
          setUserData(userTemp);
          setTradeData(tradeTemp);
          setNumberOfUniqueTraderChart(uniTradeTemp);
          setTradeAnaly(data?.platformPairUsages);
        }else{
          console.error(result?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getChartData();
  }, []);

  const darkStyle = { color: darkMode? "white" : "black" };

  return (
    <div>
      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="new monthly users"
                  chart={userData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="monthly trades"
                  chart={tradeData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Monthly Unique Traders"
                  chart={numberOfUniqueTraderChart}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <TableContainer component={Paper}>
                <Table aria-label="Trade Link Analytics">
                  <TableHead>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={1}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="checkbox"
                        colSpan={7}
                        style={{ fontSize: 'larger', fontWeight: 'bolder' }}
                        align='left'
                        >
                        <Typography
                          sx={{ flex: '1 1 100%', fontWeight: '1500' }}
                          variant="h4"
                          id="tableTitle"
                          component="div"
                        >
                          Trade Link Analytics
                        </Typography>
                      </TableCell>
                    </TableRow> 
                  </TableHead>
                  <TableHead style={{ display: 'contents' }}>
                    <TableRow>
                      <TableCell style={darkStyle}>Trader Platform</TableCell>
                      <TableCell style={darkStyle}>Copy Cat Platform</TableCell>
                      <TableCell style={darkStyle}>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tradeAnaly.map((row, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell style={darkStyle}>{row?.traderPlatformName}</TableCell>
                        <TableCell style={darkStyle}>{row?.copyCatPlatformName}</TableCell>
                        <TableCell style={darkStyle}>{row?.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default Dashboard;