import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";

import breakpoints from "assets/theme/base/breakpoints";

import backgroundImage from "assets/images/fxbackground.png";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.2),
              rgba(gradients.info.state, 0.2)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover", 
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {children}
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
