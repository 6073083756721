import React, { useEffect, useState } from "react";
import axiosHelper from "../../../Utilities/axiosHelper";
import { API_ENDPOINTS } from "apiConfig";
import { useMaterialUIController } from "context";
import yaml from 'js-yaml';

const ChangeLog  = () => {

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
    const [changeLog, setChangeLog] = useState("");
    const textColor = React.useMemo(()=> {
      if (darkMode) {
        return '#ffffff';
      }else{
        return 'black';
      }
    }, [darkMode]);

    const getChanges = async() => {
        let res = await axiosHelper.get(API_ENDPOINTS.health.changes);
        if (res.status === 200) {
            setChangeLog(yaml.load(res.data));
        }
    }
    useEffect(()=> {
        getChanges();
    }, []);
    return (
        <div className="px-4">
          {changeLog?.versions?.map((version, index) => (
            <div key={index}>
              <p style={{ color: textColor }} className="text-lg">- Version: {version.version}</p>
              <p style={{ color: textColor }} className="text-lg ml-2">Features:</p>
              <ul style={{ color: textColor }} className="text-lg ml-5">
                {version.Features.map((feature, featureIndex) => (
                  <li key={featureIndex} style={{ color: textColor }}>- {feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
    );
}

export default ChangeLog;