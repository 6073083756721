import "./modal.css";
import React from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import { Typography } from "antd";
const { Text } = Typography;

const DetailModal = ({ open, setOpen, data }) => {

  const handleClose = () => {
    setOpen(false);
  }; 
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', fontSize: 24, color: textColor }}>Details</h3>}
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      width={700}
      footer={[]}
    >
      <div className="mb-[20px]">
        <Text style={{ fontSize: 18, color: textColor }}>UID: {data.uid}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>TradeLink ID: {data.tradeLinkId}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>Broker Order ID: {data.brokerOrderId}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>Take Profit: {data.takeProfit}</Text><br/>
        <Text style={{ fontSize: 18, color: textColor }}>Stop Loss: {data.stopLoss}</Text><br/>
      </div>
    </Modal>
  );
};

export default DetailModal;
