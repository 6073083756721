import * as React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Header from "./components/Header";
import { connect } from "react-redux";
import headerBackgroundImage  from "assets/images/info.png";
import { useMaterialUIController } from "context";
import { loadUser } from "api/auth";
import tradeLocker from "assets/images/tradeLocker.jpg";
import matchTrader from "assets/images/matchTrader.jpg";
import metaTrader4 from "assets/images/metaTrader4.jpg";
import metaTrader5 from "assets/images/Meta-Trader5.jpg";
import ThumbnailAccordion from  '../../components/YoutubeAccordian';


const iframeClassName = "w-full lg:h-full md:h-[300px] h-[200px] ";


function GettingStarted({ user, loadUser }) {

  React.useEffect(() => {
    if (user === null) {
      loadUser();
    }
  },[loadUser, user]);

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
  
  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);


  const stepOneTutorials = [
    {
      title: "Setting up Meta Trader 4 & 5 (Signal Sender)",
      videos: [
        { id: "1rt_MAnpsRI", title: "How to set up Meta Trader for copy trading" }
      ],
      render: (videoLoaded, handleThumbnailClick) => (
        videoLoaded === 0 ? (
          <iframe
            className={iframeClassName}
            src="https://www.youtube.com/embed/1rt_MAnpsRI"
            loading="lazy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="https://img.youtube.com/vi/1rt_MAnpsRI/0.jpg"
            alt="Setting up Meta Trader thumbnail"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(0)}
          />
        )
      ),
    },
    {
      title: "Using TradingView as a signal generator",
      videos: [
        { id: "L25cQQF9I3o", title: "" }
      ],
      render: (videoLoaded, handleThumbnailClick) => (
        videoLoaded === 0 ? (
          <iframe
            className={iframeClassName}
            src="https://www.youtube.com/embed/L25cQQF9I3o"
            loading="lazy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="https://img.youtube.com/vi/L25cQQF9I3o/0.jpg"
            alt="Using Tradingview as a trade generator thumbnail"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(0)}
          />
        )
      ),
    }
  ];

  const stepTwoTutorials = [
    {
      title: "Setting up Meta Trader 4 & 5 (Receiver)",
      videos: [
        { id: "1rt_MAnpsRI", title: "Set up for receivers is the exact same as senders" }
      ],
      platforms: [
        {
          img:metaTrader4,
          linkUrl:"https://www.metatrader4.com/en/download",
          text: 'Click image to go to website to make an account'
        },
        {
          img:metaTrader5,
          linkUrl:"https://www.metatrader5.com/en/download",
        }
      ],
      render: (videoLoaded, handleThumbnailClick) => (
        videoLoaded === 0 ? (
          <iframe
            className={iframeClassName}
            src="https://www.youtube.com/embed/1rt_MAnpsRI"
            loading="lazy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="https://img.youtube.com/vi/1rt_MAnpsRI/0.jpg"
            alt="Setting up Meta Trader thumbnail"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(0)}
          />
        )
      ),
    },
    {
      title: "How to setup TradeLocker",
      videos: [
        { id: "u204bWqwtpo", title: "" }
      ],
      platforms: [
        {
          img:tradeLocker,
          linkUrl:"https://tradelocker.com/start-trading/",
        }
      ],
      render: (videoLoaded, handleThumbnailClick) => (
        videoLoaded === 0 ? (
          <iframe
            className={iframeClassName}
            src="https://www.youtube.com/embed/u204bWqwtpo"
            loading="lazy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="https://img.youtube.com/vi/u204bWqwtpo/0.jpg"
            alt="How to setup TradeLocker thumbnail"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(0)}
          />
        )
      ),
    },
    {
      title: "How to setup Match Trader",
      videos: [
        { id: "etYBBOxO0lE", title: "" }
      ],
      platforms: [
        {
          img:matchTrader,
          linkUrl:"https://mt.match-trade.com/login",
        }
      ],
      videoIds: ["etYBBOxO0lE"],
      render: (videoLoaded, handleThumbnailClick) => (
        videoLoaded === 0 ? (
          <iframe
            className={iframeClassName}
            src="https://www.youtube.com/embed/etYBBOxO0lE"
            loading="lazy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="https://img.youtube.com/vi/etYBBOxO0lE/0.jpg"
            alt="How to setup Match Trader thumbnail"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => handleThumbnailClick(0)}
          />
        )
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header backgroundImage={headerBackgroundImage}>
        <MDBox mt={5} mb={3}>
          <div className="md:grid grid-cols-11 px-5 md:pb-16 lg:pb-5">
            <div className="col-span-5 space-y-[0px]">
              <div>
                <div className='justify-start'> 
                  <Typography component="div" id="transition-modal-title" variant="h5" sx={{  fontWeight: 200, marginBottom: 2 }}>
                    <p className="md:text-[22px] text-[19px] font-bold">Step 1: Set Up Master Trader you can pick from MT4 , MT5 or Trading View</p>
                  </Typography>
                </div>
                <div className=" ml-5">                  
                <ThumbnailAccordion tutorials={stepOneTutorials} darkMode={darkMode} />
                </div>
              </div>
              <div className="mt-0">
                <div className='justify-start md:pt-10 pt-10'> 
                  <Typography component="div" id="transition-modal-title" variant="h5" sx={{ fontWeight: 100, marginBottom: 2 }}>
                    <p className="md:text-[22px] text-[19px] font-bold">Step 2: Set up a Copy Cat account with one of our supported platforms </p>
                  </Typography>
                </div>
                <div className="ml-5">

                <ThumbnailAccordion tutorials={stepTwoTutorials} darkMode={darkMode} />

                </div>
              <Typography component="div" id="transition-modal-title" variant="h5" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2, marginTop: 3 }}>
                    <a className="md:text-[20px] text-[17px] ml-5" href="/tutorials-downloads">Click here to go to downloads page to download Meta Trader CopyCat instance</a>
              </Typography>
              </div>

            </div>

            <div className="col-span-1 mt-[0px]" style={{ textAlign: '-webkit-center' }}>
              <Divider orientation="vertical" className=" hidden md:block" flexItem />
            </div>
            <div className="col-span-5">
              <div className='justify-start'> 
                <Typography component="div" id="transition-modal-title" variant="h5" sx={{fontWeight: 100, marginBottom: 2 }}>
                  <p className="md:text-[22px] text-[19px] font-bold">Step 3: Create Your Accounts inside of Copier Cat</p>
                </Typography>
              </div>
              <div className="md:h-[300px] h-[200px] lg:h-[400px] ml-5">
                <Typography component="div" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                  <p className="md:text-[20px] text-[19px]" style={{ color: textColor }}>Intro to Copier Cat pt1</p>
                </Typography>
                <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/L0VpO7uGTdo?si=-z_WPBTCWG7oBWWZ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
              <div className="mt-[60px]">
                <Typography component="div" id="transition-modal-title" variant="h5" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2 }}>
                  <a className="md:text-[20px] text-[17px] ml-5" href="/account">Click here to view Accounts Page</a>
                </Typography>
              </div>
              <div className='justify-start mt-10'> 
                <Typography component="div" id="transition-modal-title" variant="h5" sx={{fontWeight: 100, marginBottom: 2, marginTop:10 }}>
                  <p className="md:text-[22px] text-[19px] font-bold">Step 4: Link accounts in the Trade link page</p>
                </Typography>
              </div>
              <div>
                <Typography component="div" id="transition-modal-title" variant="h5" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2 }}>
                  <a className="md:text-[20px] text-[17px] ml-5" href="/trade-link">Click here to view trade link page</a>
                </Typography>
              </div>
            </div>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, { loadUser })(GettingStarted);