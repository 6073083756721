import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ children, user }) => {
  const location = useLocation();

  if (user === false) {
    localStorage.removeItem('tokenExpiration');
    return <Navigate to="/login" replace />;
  }
  if (localStorage.getItem('tokenExpiration') == null && location.pathname !== '/login' && location.pathname !== '/signup' ) {
    localStorage.removeItem('tokenExpiration');
    return <Navigate to="/login" replace />;
  }
  if (localStorage.getItem('tokenExpiration') != null && location.pathname === '/login' && user === true) {
    return <Navigate to="/dashboard" replace />;
  }
  if (localStorage.getItem('tokenExpiration') != null && location.pathname === '/login') {
    return <Navigate to="/dashboard" replace />;
  }
  if (localStorage.getItem('tokenExpiration') != null && location.pathname === '/signup') {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

const mapStateToProps = (state) => ({
  user: state.auth.user?.isVerified,
});

export default connect(mapStateToProps, {})(ProtectedRoute);