import {
    GET_STATUS
  } from "../api/types";
  
  const initalState = {
    statusInfo: null,
  };
  
  export default function dashboard(state = initalState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_STATUS:
          return {
            ...state,
            statusInfo: payload,
          };
        default:
            return state;
    }
  }