import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useAuth } from "../../../components/AuthContext";
import { useNavigate } from "react-router-dom";
import BasicLayout from "Pages/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import brandWhite from "assets/images/logo-ct.png";
import MDSnackbar from "components/MDSnackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { loadUser } from "../../../api/auth";
import PropTypes from "prop-types";
import {API_ENDPOINTS} from "../../../apiConfig";
import axiosHelper from "../../../Utilities/axiosHelper";
import { setTokenExpirationTime } from "../../../Utilities/authUtility";
import { getBrokerInfo, getCurrentProfile, getPlatforms, getUserPlatforms } from "api/profile";
import { getTradeHistory, getTradeLink } from "api/trade";
import { getServerInfo } from "api/infoPage";
import { getStatus } from "api/dashboard";
import { useMaterialUIController } from "context";
import { makeStyles } from '@mui/styles';

const useStylesConfirm = makeStyles((theme) => ({
  dialogDark: {
    backgroundColor: 'black', 
  },
  dialogWhite: {
    backgroundColor: 'white', 
  },
}));

function Login({ loadUser, user, getBrokerInfo, getCurrentProfile, getPlatforms, getUserPlatforms, getTradeHistory, getTradeLink, getServerInfo, getStatus }) {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");
  const [isVerified, setIsVerified] = useState("true");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openReVerify, setOpenReVerify] = useState(false);

  const handleClickOpenReVerify = () => {
  setOpenReVerify(true);
};

  const handleCloseReVerify = () => {
  setOpenReVerify(false);
};


  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='Sign In'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='Sign In'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color='warning'
      icon='star'
      title='Sign In'
      content={content}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='Sign In'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const [data, setData] = useState({ email: "", password: "" });
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const { setLogin } = useAuth();
  const navigate = useNavigate();
  const handleLoginSuccess = async() => {
    setTokenExpirationTime()
    setLogin();
    let flag = false;
    if (user === null) {
      flag = await loadUser();
    }
    if (flag && !localStorage.getItem('firstUser')) {
      localStorage.setItem('firstUser', "0");
      navigate("/getting-started");
    }else{
      getBrokerInfo();
      getPlatforms();
      getUserPlatforms();
      if (user?.isSubscribed) {
        getTradeHistory(1);
      }
      getTradeLink();
      getServerInfo();
    }
  };

  const onResendVerification = async() => {
    try {
      var response = await axiosHelper.post(API_ENDPOINTS.user.resendToken, data['email'][0]);
      if (response.status === 200) {
        setContent("Resend verification is successful. Please click verification link on your mail before log in.");
        setSuccessSB(true);
      }else{
        setContent(response.message);
        setErrorSB(true);
      }
    } catch (error) {
      setContent("Resend verification failed");
      setErrorSB(true);
    }
  }

  const onChange = (e) => {
    if (e.target.id === "email" && e.target.value !== "") {
      setEmailError("");
    }
    if (e.target.id === "password" && e.target.value !== "") {
      setPasswordError("");
    }
    setData({
      ...data,
      [e.target.id]: [e.target.value],
    });
  };

  const enterPassword = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const login = async () => {
    try {
      var response = await axiosHelper.post(API_ENDPOINTS.baseEndpoints.login, {
        email: data["email"][0],
        password: data["password"][0],
      });
      if (response.status === 200) {
        if (response.data.csrfToken) {
          localStorage.setItem("csrfToken", response.data.csrfToken);
      }
        setSuccessSB(true);
        setContent("Log in successful!");
        handleLoginSuccess();
      }else{
        setContent(`Login failed: ${response.message}`);
        setErrorSB(true);
      }
    } catch (error) {
      if (error.response) {
        const serverMessage = error.response.data;
        setContent(`Login failed: ${serverMessage}`);
        if (serverMessage === 'User not Verified') {
          setContent('Please verify your email before signing in !');
          setIsVerified(false);
        }
        setErrorSB(true);
      } else {
        setContent(`Login failed: ${error}`);
        setErrorSB(true);
      }
    }
  };

  const handleLogin = (event) => {
    if (data["email"][0] === "" || data["email"] === "") {
      setEmailError("Email is required!");
      setErrorSB(true);
      setContent("Email is required!");
      return;
    }
    if (data["password"][0] === "" || data["password"] === "") {
      setPasswordError("Password is required!");
      setErrorSB(true);
      setContent("Password is required!");
      return;
    }
    login(); 
  };

  const onForgotPassword = async() => {
    try {
      let response = await axiosHelper.post(API_ENDPOINTS.user.forgotPassword, {email: data["email"][0]});
      if (response.status === 200) {
        setContent("Forgot password request has been sent to your email.");
        setSuccessSB(true);
      }else{
        setContent(response.message);
        setErrorSB(true);
      }
      handleClose();
    } catch (error) {
      
    }
  }
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
  
  const classesConfirm = useStylesConfirm();
  
  return (
    <BasicLayout image={bgImage}>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      {renderInfoSB}
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: darkMode ? classesConfirm.dialogDark : classesConfirm.dialogWhite }}
      >
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email, if an account exists you will receive a reset link
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            value={data["email"]}
            onChange={(e) => onChange(e)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={onForgotPassword}>Reset Password</Button>
        </DialogActions>
      </Dialog>
      <Dialog
  open={openReVerify}
  onClose={handleCloseReVerify}
  classes={{ paper: darkMode ? classesConfirm.dialogDark : classesConfirm.dialogWhite }}
>
  <DialogTitle>Resend Verification Link</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Please enter your email to resend the verification link.
    </DialogContentText>
    <TextField
      autoFocus
      required
      margin="dense"
      id="email"
      name="email"
      label="Email Address"
      type="email"
      value={data["email"]}
      onChange={(e) => onChange(e)}
      fullWidth
      variant="standard"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseReVerify}>Cancel</Button>
    <Button type="submit" onClick={onResendVerification}>Resend</Button>
  </DialogActions>
</Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <div className="w-full flex">
            <img
              src={brandWhite}
              style={{ width: 240, height: 110 }}
              alt=""
              className="mx-auto rounded-full"
            />
          </div>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                id="email"
                label="Email"
                fullWidth
                value={data["email"]}
                onChange={(e) => onChange(e)}
                required
              />
              {emailError && (
                <div className="error" style={{ fontSize: 15, color: "red" }}>
                  {emailError}
                </div>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                id="password"
                value={data["password"]}
                onChange={(e) => onChange(e)}
                onKeyDown={enterPassword}
                required
              />
              {passwordError && (
                <div className="error" style={{ fontSize: 15, color: "red" }}>
                  {passwordError}
                </div>
              )}
            </MDBox>
  
            {/* Always show both Forgot Password and Resend Verification Link */}
            <MDBox display="flex" alignItems="center" ml={-1}>
              <div className="ml-2 underline-offset-1">
              <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleClickOpenReVerify} 
              style={{ color: "blue" }}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Resend Verification Link
            </MDTypography>
              </div>
            </MDBox>
  
            <MDBox display="flex" alignItems="center" ml={-1}>
              <div className="ml-2 underline-offset-1">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleClickOpen}
                  style={{ color: "blue" }}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Forgot Password ?
                </MDTypography>
              </div>
            </MDBox>
  
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleLogin}
                fullWidth
                id="handleLogin"
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
  
}

Login.propTypes = {
  loadUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, {loadUser, getServerInfo, getStatus, getBrokerInfo, getCurrentProfile, getPlatforms, getUserPlatforms, getTradeHistory, getTradeLink})(Login);
