import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getPublicAccounts } from "api/profile";
import {
    Card,
    CardContent,
    Typography,
    Grid,
  } from "@mui/material";
  import linkActive from "assets/images/link_active.png";

const Public = ({ getPublicAccounts, publicAccounts, tradeLink }) => {

    useEffect(()=> {
        getPublicAccounts();
    }, [getPublicAccounts]);

    return (
        <div>
            <Grid container spacing={2}>
                {publicAccounts.map((account) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={account.id}>
                            <Card elevation={3} style={{ margin: "10px" }}>
                                <CardContent>
                                    <div className="flex justify-between">
                                    <Typography variant='h5' gutterBottom>
                                        {account?.accountName}
                                    </Typography>
                                    {account.isActive && <img src={linkActive} alt='Share' className='w-6 h-6' />}
                                    </div>
                                    <Typography variant='h6' style={{ fontSize: 'large' }}>
                                        Created Date: {account?.createdDate}
                                    </Typography>
                                    <Typography variant='h6' style={{ fontSize: 'large' }}>
                                        Last Signal Date: {account?.lastSignalDate.includes("0001-01-01T00")? "No signals sent yet" : account?.lastSignalDate}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    publicAccounts: state.profile?.publicAccounts,
    tradeLink: state.tradeLink?.tradeLink || [],
  });

export default connect(mapStateToProps, { getPublicAccounts })(Public);